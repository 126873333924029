.iconly-regular {
  height: 24px;
  position: relative;
  width: 24px;
}

.iconly-regular .home {
  height: 22px;
  left: 2px;
  position: absolute;
  top: 1px;
  width: 20px;
}
