.privacy {
    font-family: 'Open Sans', sans-serif;
    line-height: 21px;
    max-width: 1024px;
    padding-bottom: 50px;
    @media only screen and (max-width: 600px) {
     padding-top: 30px;
     padding-bottom: 40px;
     line-height: 24px;
    }
   
    h1 {
        text-align: left;
        font-size: 24px;
        font-family: 'Open Sans', sans-serif;
        font-style: normal;
    }
    p{
        font-size: 14px;
    }

    .Title {
        font-size: 16px;
        font-family:'Open Sans', sans-serif;
        color: var(--text-icon-primary-black, #1D2433);
        font-style: normal;
        font-weight: 400;
    }
    .sub{
      color:  var(--text-icon-secondary-black, rgba(29, 36, 51, 0.80));
    }
    .ul{
        padding-bottom: 10px;
        font-size: 14px;
    }
    a{
        text-decoration: underline;
    }

   
}