 .CT-as {
    margin: 0 auto;
    margin-top: 4rem;
    @media only screen and (max-width: 600px) {
        background-color: #0151FA;
        border-radius: 0;
    }
    .overlap-group {
      border-radius: 24px;
      // max-height: 532px;
      position: relative;
      display: flex;
      justify-content: center;
      align-items: center;
      margin: 0 auto;
      background-color: #0151fd;

      .frame-wrapper {
        align-items: center;
        background-color: #0151fd;
        border-radius: 24px;
        display: flex;
        justify-content: space-between;
        // gap: 60px;
        left: 0;
        padding: 90px 50px;
        // position: absolute;
        // top: 0;
        width: 100%;

        .frame-9 {
          align-items: center;
          display: flex;
          flex-direction: column;
          justify-content: center;
          position: relative;
          width: fit-content;
          @media only screen and (max-width: 600px) {
            align-items: start;
          }
        }

        @media only screen and (max-width: 600px) {
          padding: 15px 0;
        }
      }

      @media only screen and (max-width: 600px) {
        width: 100%;
      }
      @media only screen and (max-width: 950px) {
        width: 100%;
      }
      @media only screen and (max-width: 1024px) {
        width: 100%;
      }
      @media only screen and (max-width: 1224px) {
        width: 100%;
      }

      .img-ready {
        width: 57%;

        img {
          object-fit: cover;
          max-height: 632px;
          border-radius: 18px;
        }
        @media only screen and (max-width: 600px) {
          display: none;
        }
        @media only screen and (max-width: 1024px) {
          width: 75%;
          max-height: 532px;
        }
      }
    }

    .phone1 {
      @media only screen and (max-width: 600px) {
        display: none;
      }
    }

    .btn {
        background-color: #a7f46a;
        font-family: "Open Sans", Helvetica;
        padding: 18px;
        border: none;
        border-radius: 8px;
        width: 287px;
        color: #1d2433;
        font-weight: 600;
        font-size: 16px;
        cursor: pointer;
        border: 1px solid #a7f46a;
        @media only screen and (max-width: 600px) {
          width: 187px;
          font-size: 14px;
        }
      }
      .btn:hover {
        // opacity: 0.5;
        // background-color: #ffff;
         color: #fff;
        border: 1px solid #ffff;
      }
      .text-wrapper-6 {
        outline: none;
        position: relative;
        white-space: nowrap;
        width: fit-content;
        border: #ffffff;
        cursor: pointer;
    
        &:hover {
          outline-color: #a7f46a;
        }
        @media only screen and (max-width: 600px) {
        }
      }
    
      ::placeholder {
        color: #ffffff;
        opacity: 0.9;
      }
  }



  