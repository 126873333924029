* {
  /* -webkit-font-smoothing: antialiased; */
  box-sizing: border-box;
  font-family: 'Open Sans', Helvetica;;
}

html,
body {
  margin: 0px;
  height: 100%;
  background-color: #fff;
}

a {
  text-decoration: none;
}

.max-width {
  max-width: 1440px;
  width: 100%;
  margin: 0 auto;
  padding: 20px 100px;

  @media only screen and (max-width: 1024px) {
    padding: 0 20px;
  }
}