.Terms{
    font-family: 'Open Sans', sans-serif;
    line-height: 21px;
    max-width: 1024px;
    padding-bottom: 50px;
    @media only screen and (max-width: 600px) {
     padding-top: 30px;
     padding-bottom: 40px;
     line-height: 24px;
    }

    .top{
        font-size: 14px;
        font-family:'Open Sans', sans-serif;
        color: var(--text-icon-primary-black, #1D2433);
        font-style: normal;
        font-weight: 400;
    }
    h1{
        font-size: 24px;
        margin-top: 35px;
    }
    h2{
        font-size: 16px;
    }
    p{
        font-size: 14px;
    }
    .definition{
        padding-left: 56px;
    }
    a{
        text-decoration: underline;
    }
}