.sales-page {
  // background-color: #ffffff;

  width: 100%;
  max-width: 1440px;
  margin: 0 auto;
  padding: 20px;

  .contactSales {
    color: #0151fd;
  }

  .h-1 {
    @media only screen and (max-width: 600px) {
      font-size: 28px;
    }
  }

  .form-section {
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 100px;

    // padding: 0 55px;
    // margin-bottom: -10rem;
    @media only screen and (max-width: 600px) {
      padding: 0 20px;
    }

  }

  .form-section-left {
    margin: auto 0;
    padding: auto 0;
    width: 50%;

    @media only screen and (max-width: 600px) {
      width: 100%;
    }
  }

  .label {
    font-family: 'Open Sans', sans-serif;
    color: #1d2433;
    margin-left: 7px;
    // margin-bottom: 15px;
  }

  .text-area {
    // margin-top: 10px;
    padding: 15px 10px;
    // margin-left:10px;
    width: 100%;
    height: 128px;
    resize: none;
    border: #E1E6EF 1px solid;
    border-radius: 8px;
    outline: #e1e6ef;

    @media only screen and (max-width: 600px) {
      width: 100%;
    }
  }

  .checkbox {
    margin-left: 0;
    padding-left: 0;
    display: flex;
    width: 100%;
    align-items: center;

    @media only screen and (max-width: 600px) {
      width: 100%;
    }

  }

  .checkbox p {
    font-family: 'Open Sans', sans-serif;
    font-weight: 400;
    font-size: 14px;
    line-height: 21px;
    align-items: center;
  }

  .btn-contact {
    color: #ffffff;
    font-weight: 700;
    font-family: 'Open Sans', sans-serif;
    background-color: #0151fd;
    width: 100%;
    height: 56px;
    margin: 10px 0;
    padding: 16px, 20px;
    border-radius: 8px;
    border: none;
    cursor: pointer;
    opacity: none;
  }

  .btn-contact:hover {
    background-color: #ffffff !important;
    color: #0151fd;
    border: #0151fd 1px solid;
    cursor: pointer;

  }

  .errorMessage {
    color: red;
    margin-left: 15px;
  }

  .form-section-right {
    height: 700px;
    width: 50%;
  }

  .input {
    width: 100%;
    margin-bottom: .1rem;
    height: 90px;
  }

  // .text-area{
  //   width: 100%;
  //   margin-bottom: .1rem;
  // }

  @media only screen and (max-width: 600px) {



    .form-section-right {

      display: none;
    }



  }

  @media only screen and (max-width: 600px) {}

  
 
}