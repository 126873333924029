.slider1 {
  display: flex;
  align-items: left;
  justify-content: space-between;
  width: auto !important;
  .frame-14 {
    align-items: flex-start;
    flex-direction: column;
    justify-content: center;
    .image-wrapper {
      align-items: center;
      background-color: #eff4ff;
      border-radius: 60px;
      display: flex;
      gap: 10px;
      justify-content: center;
      position: relative;
      padding: 0.5px;
      .image-2 {
        //height: 662px;
        //position: relative;
      }
    }
  }

  .frame-15 {
    align-items: flex-start;
    align-self: stretch;
    display: flex;
    flex-direction: column;
    gap: 16px;
    justify-content: center;
    position: relative;
    width: 350px;

    .title-6 {
      align-self: stretch;
      color: var(--texticonprimary-black);
      font-family: "Open Sans", Helvetica;
      font-size: 22px;
      font-weight: 600;
      letter-spacing: 0;
      // line-height: 48px;
      margin-top: 15px;
      position: relative;
    }
  }

  li::marker {
    content: "";
  }

  ul {
    display: flex !important;
    align-items: center;
    justify-content: center;
  }

  .slick-dots {
    margin: 0 auto;

    margin-bottom: 50px;
  }

  .slick-dots li button:before {
    height: 18px;
    width: 18px;
    // position: relative;
    color: #0151fd4c;
    font-size: 10px;
    opacity: 1;
  }

  .slick-dots li.slick-active button:before {
    // height: 18px;
    // width: 48px;
    // position: relative;
    // color: #0151fd;
    // font-size: 20px;
    display: none;
  }

  .slick-dots li.slick-active {
    background-color: #0151fd;
    border-radius: 100px;
    height: 4px;
    width: 40px;
    padding-bottom: 12px;
    // position: relative;
  }

  .slider1 .iconly-sharp-bold-wrapper {
    align-items: center;
    border: 1px solid;
    border-color: #0151fd;
    border-radius: 8px;
    display: flex;
    gap: 8px;
    justify-content: center;
    padding: 16px;
    position: relative;
    width: fit-content;
    cursor: pointer;
  }

  .slider-carousel {
    // width: 100%;
    max-width: 100%;
    margin: 0 auto;
    display: flex;
    gap: 50px;
    //  padding-left: 2rem;
    border-radius: 60px;
  }

  .slider-center {
    margin: 0 auto;
  }

  .dot {
    display: flex !important;
    gap: 15px;
    width: 200px;
    margin: 0 auto;

    button {
      display: none;
    }
  }
}

// .landing-page .title-7 {
//   align-self: stretch;
//   color: var(--texticonsecondary-black);
//   font-family: var(--body-open-sans-16-regular-font-family);
//   font-size: var(--body-open-sans-16-regular-font-size);
//   font-style: var(--body-open-sans-16-regular-font-style);
//   font-weight: var(--body-open-sans-16-regular-font-weight);
//   letter-spacing: var(--body-open-sans-16-regular-letter-spacing);
//   line-height: var(--body-open-sans-16-regular-line-height);
//   position: relative;
// }
