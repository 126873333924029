









.images-slider {
  margin-bottom: 75px;
  height: 100%;
  border-radius: 20px;
  // overflow-y: hidden;
  background-image: url('../../images/slider-bg.png');
  background-size: cover;
  padding: 24px;

  .images-slider-innner {
    height: 100%;
    overflow-y: hidden;

    .testimony_box {
      /* Customize your box styles here */
      padding: 10px;
      // border: 1px solid #ccc;
      animation: smooth-scroll 30s linear 0s infinite normal running;
    }

    @keyframes smooth-scroll {
      0% {
        transform: translateY(0px);
      }

      100% {
        transform: translateY(-410px);
      }
    }
  }
  img {
    /* Ensure the images fill the container */
    width: 100%;
    height: auto;
  }
}