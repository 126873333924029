.notfound{
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;

.image{
    margin-top: 20px;
    @media only screen and (max-width: 600px) {
    margin-top: 40px;}

img{
    @media only screen and (max-width: 600px) {
    object-fit: cover;
    max-width: 352px;}
}
}


    .text{
        display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;

    @media only screen and (max-width: 600px) {
        font-size: 14px;
    }
    }



    .notfound-btn{
        background-color: #407CFE;
        color: #ffffff;
        font-size: 16px;
        font-weight: 700;
        font-family: "Open Sans", Helvetica;
        padding: 16px 20px;
        border-radius: 8px;
        border: none;
        cursor: pointer;
        border: 1px solid #407CFE;
        width: 250px;
    }
    .notfound-btn:hover{
        background-color: #ffffff;
        color: #407CFE;
        border: 1px solid #407CFE;
    }
}

