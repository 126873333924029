.aboutUs-page {

    .video-highlight {
        color: #0151FD;
        font-weight: 600;
        font-size: 16px;
        line-height: 24px;
        font-family: "Open Sans", Helvetica;
        border: 1px solid #0151FD;
        border-radius: 8px;
        padding: 18px 20px;
        gap: 8px;
    }

    .h3 {
        color: #0151FD;
        font-family: "Open Sans", Helvetica;
        font-weight: 600;
        font-size: 14px;
        line-height: 21px;
    }

    .overlap-wrapper {

        height: 600px;
        width: 300px;

        @media only screen and (max-width: 600px) {
            display: none;
        }
    }


    .section {
        color: #0151FD;
        font-family: "Open Sans", Helvetica;
        font-weight: 600;
        font-size: 14px;
        line-height: 21px;
        text-align: center;
        margin-top: 50px;

        @media only screen and (max-width: 600px) {
            text-align: center;
        }
    }

    .frame-2 {
        display: flex;
        justify-content: center;
        text-align: center;
        margin-bottom: 20px;

        @media only screen and (max-width: 600px) {
            flex-direction: column;
        }

        h1 {
            font-family: "Open Sans", Helvetica;
            font-weight: 600;
            font-size: 40px;
            line-height: 60px;
            margin-top: 30px;

            @media only screen and (max-width: 600px) {
                font-size: 20px;
                line-height: 24px;
                text-align: center;
            }
        }

        p {
            font-family: "Open Sans", Helvetica;
            margin-top: 30px;
            width: 590px;
            font-weight: 400px;
            list-style: 24px;

            @media only screen and (max-width: 600px) {
                width: 345px;
                display: flex;
                justify-content: center;
                text-align: center;
            }
        }
    }

    .frame-3 {
        display: flex;
        justify-content: space-between;
        margin-top: 10px;
        margin-bottom: 50px;


        @media only screen and (max-width: 600px) {
            flex-direction: column;
        }

        h1 {
            font-family: "Open Sans", Helvetica;
            font-weight: 600;
            font-size: 40px;
            line-height: 60px;

            @media only screen and (max-width: 600px) {
                font-size: 20px;
                line-height: 24px;
                text-align: center;
            }
        }

        p {
            font-family: "Open Sans", Helvetica;
            margin-top: 50px;
            width: 650px;
            font-weight: 400px;
            text-align: left;


            @media only screen and (max-width: 600px) {
                width: 345px;
                display: flex;
                justify-content: center;
                text-align: center;
            }
        }
    }

    .video {
        display: flex;
        margin-top: 50px;
        align-items: center;
        justify-content: center;
        // max-width: 935px;
        margin: 0 auto;

        img {
            object-fit: cover;
            // height: 100%;
            width: 100%;
        }
    }


    // // ------
    //    .landing-page {
    //     background-color: #fff;

    //     width: 100%;
    //     max-width: 1440px;
    //     margin: 0 auto;
    //     padding: 20px;
    // }

    .div-3 {
        background-color: #fff;
        border: 1px none;
        // height: 3807px;
        position: relative;
        width: 100%;
        /* max-width: 1440px; */
    }

    .footer-white {
        // height: 1061px;
        left: 0;
        // position: absolute;
        // top: 2746px;
        /* max-width: 1440px; */
        width: 100%;
    }

    .overlap {
        // height: 1061px;
        position: relative;
    }



    // .CT-as {
    //     // height: 556px;
    //     // left: 100px;
    //     // position: absolute;
    //     // top: 0;
    //     // width: 1240px;
    //     margin: 0 auto;
    //     margin-top: 5rem;

    //     .overlap-group {
    //         border-radius: 24px;
    //         // height: 556px;
    //         position: relative;
    //         display: flex;
    //         background-color: #0151fd;

    //         .frame-wrapper {
    //             align-items: center;
    //             background-color: #0151fd;
    //             border-radius: 24px;
    //             display: flex;
    //             justify-content: space-between;
    //             // gap: 60px;
    //             left: 0;
    //             padding: 90px 50px;
    //             // position: absolute;
    //             // top: 0;
    //             width: 100%;

    //             .frame-9 {
    //                 align-items: center;
    //                 display: flex;
    //                 flex-direction: column;
    //                 gap: 32px;
    //                 justify-content: center;
    //                 position: relative;
    //                 width: fit-content;
    //             }

    //             @media only screen and (max-width: 600px) {
    //                 padding: 90px 20px;
    //             }
    //         }

    //         @media only screen and (max-width: 600px) {
    //             width: 100%;
    //         }
    //     }

    //     .phone1 {

    //         @media only screen and (max-width: 600px) {
    //             display: none;
    //         }
    //     }
    // }





    .landing-page .frame-10 {
        align-items: flex-start;
        display: flex;
        flex-direction: column;
        gap: 16px;
        justify-content: center;
        position: relative;
        width: fit-content;
    }

    .p {
        color: #ffffff;
        font-family: "Open Sans", Helvetica;
        font-size: 40px;
        font-weight: 600;
        letter-spacing: 0;
        line-height: normal;
        margin-top: -1px;
        position: relative;
        max-width: 549px;
    }

    .title-3 {
        color: #ffffff;
        font-family: var(--body-open-sans-16-regular-font-family);
        font-size: var(--body-open-sans-16-regular-font-size);
        font-style: var(--body-open-sans-16-regular-font-style);
        font-weight: var(--body-open-sans-16-regular-font-weight);
        letter-spacing: var(--body-open-sans-16-regular-letter-spacing);
        line-height: var(--body-open-sans-16-regular-line-height);
        position: relative;
        max-width: 549px;
    }

    .regular-buttons-4 {
        align-items: center;
        align-self: stretch;
        border: 1px solid;
        border-color: #ffffff;
        border-radius: 16px;
        display: flex;
        justify-content: space-between;
        padding: 8px 8px 8px 16px;
        position: relative;

        input {
            width: 100%;
            height: 100%;
            background-color: transparent;
            border: none;
            color: var(--texticondisabled-white);
            font-family: var(--body-open-sans-16-semi-bold-font-family);
            font-size: var(--body-open-sans-16-semi-bold-font-size);
            font-style: var(--body-open-sans-16-semi-bold-font-style);
            font-weight: var(--body-open-sans-16-semi-bold-font-weight);
            letter-spacing: var(--body-open-sans-16-semi-bold-letter-spacing);
            line-height: var(--body-open-sans-16-semi-bold-line-height);

            &:active {
                border: none;
            }

            &:focus {
                border: none;
            }
        }
    }

    .text-wrapper-6 {
        outline: none;
        position: relative;
        white-space: nowrap;
        width: fit-content;
        border: #FFFFFF;

        @media only screen and (max-width: 600px) {
            .text-wrapper-6 ::placeholder {}
        }
    }

    ::placeholder {
        color: #FFFFFF;
        opacity: 0.9;
    }

    .design-component-instance-node {
        background-color: rgba(167, 244, 106, 1) !important;
        padding: 18px 36px !important;
    }

    .regular-buttons-5 {
        color: var(--texticonprimary-black) !important;
        font-family: var(--heading-open-sans-16-semi-bold-font-family) !important;
        font-size: var(--heading-open-sans-16-semi-bold-font-size) !important;
        font-style: var(--heading-open-sans-16-semi-bold-font-style) !important;
        font-weight: var(--heading-open-sans-16-semi-bold-font-weight) !important;
        letter-spacing: var(--heading-open-sans-16-semi-bold-letter-spacing) !important;
        line-height: var(--heading-open-sans-16-semi-bold-line-height) !important;
        white-space: nowrap !important;
    }

    .image {
        // height: 556px;
        // left: 659px;
        // position: absolute;
        // top: 0;
        // right: 0;
        // bottom: 0;
        // width: 549px;
        margin-right: 2rem;
    }

    .features {
        align-items: center;
        display: flex;
        flex-direction: column;
        gap: 60px;
        justify-content: center;
        margin-top: 10rem;
    }

    .frame-11 {
        align-items: center;
        display: flex;
        flex-direction: column;
        // gap: 16px;
        justify-content: center;
        position: relative;
        width: fit-content;
    }

    .frame-12 {
        align-items: center;
        align-self: stretch;
        display: flex;
        flex-direction: column;
        gap: 4px;
        justify-content: center;
        position: relative;
    }

    .button-instance {
        color: #0151fd !important;
        font-family: var(--heading-open-sans-14-semi-bold-font-family) !important;
        font-size: var(--heading-open-sans-14-semi-bold-font-size) !important;
        font-style: var(--heading-open-sans-14-semi-bold-font-style) !important;
        font-weight: var(--heading-open-sans-14-semi-bold-font-weight) !important;
        letter-spacing: var(--heading-open-sans-14-semi-bold-letter-spacing) !important;
        line-height: var(--heading-open-sans-14-semi-bold-line-height) !important;
    }

    .title-4 {
        color: var(--texticonprimary-black);
        font-family: "Open Sans", Helvetica;
        font-size: 40px;
        font-weight: 600;
        letter-spacing: 0;
        line-height: 60px;
        position: relative;
        text-align: center;
        max-width: 900px;

        @media only screen and (max-width: 600px) {
            font-size: 20px;
            line-height: 30px;
        }
    }

    .title-5 {
        color: var(--texticonsecondary-black);
        font-family: var(--body-open-sans-16-regular-font-family);
        font-size: var(--body-open-sans-16-regular-font-size);
        font-style: var(--body-open-sans-16-regular-font-style);
        font-weight: var(--body-open-sans-16-regular-font-weight);
        letter-spacing: var(--body-open-sans-16-regular-letter-spacing);
        line-height: var(--body-open-sans-16-regular-line-height);
        position: relative;
        text-align: center;
        white-space: wrap;
        width: 100%;
    }

    .frame-13 {
        align-items: flex-start;
        display: flex;
        gap: 48px;
        justify-content: center;
        overflow-x: scroll;
        position: relative;
        width: 1240px;
    }



    .frame-16 {
        align-items: center;
        border-radius: 100px;
        display: flex;
        gap: 10px;
        justify-content: center;
        position: relative;
        width: fit-content;
    }

    .rectangle {
        background-color: #0151fd4c;
        border-radius: 100px;
        height: 8px;
        min-width: 8px;
        position: relative;
    }

    .rectangle-2 {
        background-color: #0151fd;
        border-radius: 100px;
        height: 8px;
        min-width: 40px;
        position: relative;
    }





    .icon-instance-node {
        height: 24px !important;
        min-width: 24px !important;
        position: relative !important;
    }

    .about-us {
        align-items: center;
        display: flex;
        gap: 60px;
        justify-content: center;
        // left: 100px;
        // position: absolute;
        // top: 962px;
        margin-top: 5rem;
        width: 100%;

        @media only screen and (max-width: 600px) {
            flex-direction: column-reverse;
        }
    }

    .frame-18 {
        // height: 530px;
        max-width: 590px;
        width: 100%;
        position: relative;
    }

    .frame-19 {
        align-items: flex-start;
        display: flex;
        flex: 1;
        flex-direction: column;
        gap: 32px;
        position: relative;
    }

    .frame-20 {
        align-items: flex-start;
        align-self: stretch;
        display: flex;
        flex-direction: column;
        gap: 16px;
        position: relative;
    }

    .frame-21 {
        align-items: flex-start;
        align-self: stretch;
        display: flex;
        flex-direction: column;
        gap: 4px;
        position: relative;
    }

    .title-8 {
        align-self: stretch;
        color: var(--texticonprimary-black);
        font-family: "Open Sans", Helvetica;
        font-size: 40px;
        font-weight: 600;
        letter-spacing: 0;
        line-height: normal;
        position: relative;
    }

    .frame-22 {
        align-items: center;
        display: flex;
        justify-content: space-between;
        position: relative;
        max-width: 503px;
        gap: 50px;
        margin-top: -45px;
    }

    .iconBox {
        border-right: 1px #E1E6EF solid;
        padding-right: 40px;
    }

    .iconBox_2 {
        border-right: none;
    }

    .numIcon {
        color: #0151FD;
        font-size: 48px;
        font-weight: 700;
        padding-right: 40px;
    }

    .iconColor {
        color: #1D2433;
        font-size: 14px;
        font-family: "Open Sans", Helvetica;
        font-weight: 600;
        width: 97px;
        margin-top: -45px;


    }

    .info {
        color: #0151FD;
        font-family: "Open Sans", Helvetica;
        background: none;
        border: none;
        font-weight: 600;
        line-height: 24px;
        display: flex;
        text-align: center;
        cursor: pointer;
    }

    .info:hover {
        opacity: 0.5;
    }

    // .landing-page .frame-23 {
    //   align-items: flex-start;
    //   display: flex;
    //   flex-direction: column;
    //   justify-content: flex-end;
    //   position: relative;
    //   width: fit-content;
    // }

    .title-9 {
        color: #0151fd;
        font-family: "Open Sans", Helvetica;
        font-size: 48px;
        font-weight: 700;
        letter-spacing: 0;
        line-height: normal;
        margin-top: -1px;
        position: relative;
        width: fit-content;
    }

    .title-10 {
        color: #1d243380;
        font-family: var(--heading-open-sans-14-semi-bold-font-family);
        font-size: var(--heading-open-sans-14-semi-bold-font-size);
        font-style: var(--heading-open-sans-14-semi-bold-font-style);
        font-weight: var(--heading-open-sans-14-semi-bold-font-weight);
        letter-spacing: var(--heading-open-sans-14-semi-bold-letter-spacing);
        line-height: var(--heading-open-sans-14-semi-bold-line-height);
        position: relative;
        white-space: nowrap;
        width: fit-content;
    }

    .line {
        height: 56px;
        min-width: 1px;
        object-fit: cover;
        position: relative;
    }

    .regular-buttons-6 {
        justify-content: unset !important;
        padding: unset !important;
    }

    .regular-buttons-7 {
        color: #0151fd !important;
        font-family: var(--heading-open-sans-16-semi-bold-font-family) !important;
        font-size: var(--heading-open-sans-16-semi-bold-font-size) !important;
        font-style: var(--heading-open-sans-16-semi-bold-font-style) !important;
        font-weight: var(--heading-open-sans-16-semi-bold-font-weight) !important;
        letter-spacing: var(--heading-open-sans-16-semi-bold-letter-spacing) !important;
        line-height: var(--heading-open-sans-16-semi-bold-line-height) !important;
        white-space: nowrap !important;
    }

    .partners {
        align-items: center;
        background-color: #fff;
        display: flex;
        flex-direction: column;
        gap: 32px;
        justify-content: center;
        // left: 0;
        // position: absolute;
        // top: 775px;
        /* max-width: 1440px; */
        margin-top: 3rem;
        width: 100%;
    }

    .title-11 {
        align-self: stretch;
        color: var(--texticonprimary-black);
        font-family: "Open Sans", Helvetica;
        font-size: 24px;
        font-weight: 600;
        letter-spacing: 0;
        line-height: normal;
        margin-top: -1px;
        position: relative;
        text-align: center;
    }

    .frame-24 {
        align-items: flex-start;
        display: flex;
        gap: 64px;
        position: relative;
        width: 100%;
    }

    .rectangle-3 {
        background-color: #eff4ff;
        flex: 1;
        height: 32px;
        min-width: 120px;
        position: relative;
    }

    .header {
        align-items: center;
        background-color: transparent;
        display: flex;
        gap: 60px;
        margin-top: 3rem;
        // width: 100%;
        padding: 0;

        @media only screen and (max-width: 600px) {
            flex-direction: column;
            margin-top: -4rem;
            padding: 0 15px;
        }
    }

    .frame-25 {
        align-items: flex-start;
        display: flex;
        flex: 1;
        flex-direction: column;
        gap: 32px;
        justify-content: center;
        position: relative;
        width: 50%;

        @media only screen and (max-width: 600px) {
            width: 100%;
        }
    }

    .h-1 {
        align-self: stretch;
        color: transparent;
        font-family: "Open Sans", Helvetica;
        font-size: 48px;
        font-weight: 700;
        letter-spacing: 0;
        line-height: 72px;
        margin-top: -1px;
        position: relative;

        @media only screen and (max-width: 600px) {
            font-size: 30px;
            line-height: 52px;
        }
    }

    .span {
        color: #1c2433;
    }

    .text-wrapper-7 {
        color: #0151fd;
    }

    .yellow-text {
        color: rgb(109, 162, 65);
    }

    .design-component-instance-node-2 {
        background-color: #0151fd !important;
        border: 1px solid !important;
        padding: 16px 20px !important;
        width: 150px !important;
        color: #ffffff;
    }

    .regular-buttons-8 {
        font-family: var(--heading-open-sans-16-bold-font-family) !important;
        font-size: var(--heading-open-sans-16-bold-font-size) !important;
        font-style: var(--heading-open-sans-16-bold-font-style) !important;
        font-weight: var(--heading-open-sans-16-bold-font-weight) !important;
        letter-spacing: var(--heading-open-sans-16-bold-letter-spacing) !important;
        line-height: var(--heading-open-sans-16-bold-line-height) !important;
        white-space: nowrap !important;
    }

    .overlap-wrapper {
        // height: 549px;
        // min-width: 572px;
        position: relative;
        width: 50%;

        img {
            width: 100%;
            object-fit: cover;
        }

        @media only screen and (max-width: 600px) {
            width: 100%;
        }
    }

    .overlap-2 {
        height: 549px;
        position: relative;
        width: 572px;
    }

    .overlap-group-wrapper {
        border: 1px solid;
        border-color: #0151fd;
        border-radius: 254.5px;
        height: 509px;
        left: 43px;
        position: absolute;
        top: 40px;
        width: 509px;
    }

    .overlap-group-2 {
        height: 448px;
        left: 80px;
        position: relative;
        top: 31px;
        width: 349px;
    }

    .ellipse {
        background-color: #0151fd;
        border: 1px solid;
        border-radius: 174.5px;
        height: 349px;
        left: 0;
        position: absolute;
        top: 49px;
        width: 349px;
    }

    .image-3 {
        height: 448px;
        left: 82px;
        object-fit: cover;
        position: absolute;
        top: 0;
        width: 186px;
    }

    .group-2 {
        height: 364px;
        left: 0;
        position: absolute;
        top: 0;
        width: 572px;
    }

    .frame-instance {
        left: 435px !important;
        position: absolute !important;
        top: 123px !important;
    }

    .frame-20-instance {
        font-family: "Open Sans", Helvetica !important;
    }

    .pepicons-pencil-wrapper {
        left: 455px !important;
        position: absolute !important;
        top: 312px !important;
    }

    .logos-facebook-wrapper {
        left: 15px !important;
        position: absolute !important;
        top: 123px !important;
    }

    .fluent-payment-wrapper {
        left: 0 !important;
        position: absolute !important;
        top: 312px !important;
    }

    .logos-whatsapp-icon-wrapper {
        left: 229px !important;
        position: absolute !important;
        top: 0 !important;
    }

    //marchant-section
    .btn {

        background-color: #A7F46A;
        font-family: "Open Sans", Helvetica;
        padding: 18px;
        border: none;
        border-radius: 8px;
        width: 287px;
        color: #1D2433;
        font-weight: 600;
        font-size: 16px;

        @media only screen and (max-width: 600px) {
            width: 187px;
            font-size: 14px;
        }
    }

    .marchant-section {
        // background-color: #FAFAFA;
        padding-top: 30px;
        padding-bottom: 30px;
        // margin-top: 50px;
    }

    .digital-section {
        display: flex;
        justify-content: space-between;
        margin-top: 20px;
        width: 1440px;
        gap: 60px;
    
    
        @media only screen and (max-width: 600px) {
            flex-direction: column;
        }
    
    
        .digital-section-left {
            display: flex;
            width: 50%;
            height: 524px;
            border-radius: 16px;
    
            img {
                object-fit: cover;
                height: 100%;
                width: 100%;
            }
    
            @media only screen and (max-width: 600px) {
                width: 332px;
            }
    
        }
    
        .digi-container {
            width: 50%;
        }
    
        .digital-section-right {
            display: flex;
            justify-content: space-between;
            gap: 24px;
    
    
            @media only screen and (max-width: 600px) {
                width: 332px;
            }
    
    
            .icons {
                width: 48px;
    
                img {
                    object-fit: cover;
                    width: 100%;
                }
            }
    
            .digital-text {
                font-family: Open Sans;
                font-size: 14px;
                font-style: normal;
                font-weight: 400;
                line-height: 21px;
                text-align: left;
                margin-bottom: 40px;
                padding-bottom: 32px;
                border-bottom: 2px #F1F3F9 solid;
    
    
    
                h1 {
                    font-family: Open Sans;
                    font-size: 16px;
                    font-style: normal;
                    font-weight: 600;
                    line-height: 24px;
                }
            }
    
    
    
        }
    }

}

