.footer{

  .footer-white-2 {
    margin-top: 5rem;
    align-items: flex-start;
    gap: 32px;
    @media only screen and (max-width: 600px) {
      flex-direction: column;
      margin-top: 2rem;
    }
  }
  
  .frame-5 {
    align-items: flex-start;
    display: flex;
    justify-content: space-between;
    gap: 100px;
    position: relative;
    flex-wrap: wrap;
    max-width: 100%;
    @media only screen and (max-width: 600px) {
      flex-direction: column;
      gap: 50px
    }
  }
  .foot{
    display: flex;
    flex-direction: column;
  }
  .div-3 {
    align-items: flex-start;
    display: flex;
    justify-content: space-between;
    flex-direction: column;
    gap: 20px;
    position: relative;
    width: fit-content;
     margin-top: 1rem;
  }
  .div-4 {
    align-items: flex-start;
    display: flex;
    justify-content: space-between;
    flex-direction: column;
    gap: 20px;
    position: relative;

    @media only screen and (max-width: 600px) {
      width:100%;
    }
  }
  
  .img {
    height: 24px;
    min-width: 110.32px;
    position: relative;
  }
  
  .lorem-ipsum-dolor-si {
    color: #1e2832;
    font-family: var(--body-open-sans-16-regular-font-family);
    font-size: var(--body-open-sans-16-regular-font-size);
    font-style: var(--body-open-sans-16-regular-font-style);
    font-weight: var(--body-open-sans-16-regular-font-weight);
    letter-spacing: var(--body-open-sans-16-regular-letter-spacing);
    line-height: var(--body-open-sans-16-regular-line-height);
    position: relative;
    width: 600px;
    @media only screen and (max-width: 280px) {
      max-width: 250px;
      font-size: 12px;
      line-height: 16px;
      text-align: center;
  }
    @media only screen and (max-width: 600px) {
      width:100%;
      text-align: justify;
    }
  }
  
  .text-wrapper-2 {
    color: var(--texticonprimary-black);
    font-weight: 700;
    position: relative;
    white-space: nowrap;
    width: fit-content;
  }
  
  .frame-6 {
    align-items: flex-start;
    display: flex;
    flex-direction: column;
    gap: 16px;
    position: relative;
    width: fit-content;
    @media only screen and (max-width: 280px) {
      max-width: 250px;
      font-size: 12px;
      line-height: 16px;
      text-align: center;
  }
  }
  
  .text-wrapper-3 {
    color: #1e2832;
    font-family: var(--body-open-sans-16-regular-font-family);
    font-size: var(--body-open-sans-16-regular-font-size);
    font-style: var(--body-open-sans-16-regular-font-style);
    font-weight: var(--body-open-sans-16-regular-font-weight);
    letter-spacing: var(--body-open-sans-16-regular-letter-spacing);
    line-height: var(--body-open-sans-16-regular-line-height);
    margin-top: -1px;
    position: relative;
    white-space: nowrap;
    width: fit-content;
  }
  .emai-icon{
    align-items: center;
    display: flex;
  }
  
  .text-wrapper-4 {
    color: #1e2832;
    font-family: var(--body-open-sans-16-regular-font-family);
    font-size: var(--body-open-sans-16-regular-font-size);
    font-style: var(--body-open-sans-16-regular-font-style);
    font-weight: var(--body-open-sans-16-regular-font-weight);
    letter-spacing: var(--body-open-sans-16-regular-letter-spacing);
    line-height: var(--body-open-sans-16-regular-line-height);
    position: relative;
    white-space: nowrap;
    width: fit-content;
  }
  
  .learning {
    align-items: flex-start;
    display: flex;
    flex-direction: column;
    gap: 12px;
    position: relative;
    width: fit-content;
  }
  
  .text-wrapper-5 {
    color: var(--texticonsecondary-black);
    font-family: var(--heading-open-sans-16-regular-font-family);
    font-size: var(--heading-open-sans-16-regular-font-size);
    font-style: var(--heading-open-sans-16-regular-font-style);
    font-weight: var(--heading-open-sans-16-regular-font-weight);
    letter-spacing: var(--heading-open-sans-16-regular-letter-spacing);
    line-height: var(--heading-open-sans-16-regular-line-height);
    position: relative;
    white-space: nowrap;
    width: fit-content;
  }
  
  .divider {
    height: 3px;
    max-width: 100%;
    position: relative;
    width: 100%;
    background-color:#FAFAFA;;
    margin: 24px 0;
  }
  
  .frame-7 {
    align-items: center;
    display: flex;
    justify-content: space-between;
    position: relative;
    max-width: 100%;
    @media only screen and (max-width: 280px) {
      max-width: 250px;
      font-size: 12px;
      line-height: 16px;
      text-align: center;
  }
    @media only screen and (max-width: 600px) {
      flex-direction: column;
      gap: 20px
    }
  }
  
  .element-APCOM-all-ri {
    color: var(--texticonsecondary-black);
    font-family: var(--body-open-sans-14-regular-font-family);
    font-size: var(--body-open-sans-14-regular-font-size);
    font-style: var(--body-open-sans-14-regular-font-style);
    font-weight: var(--body-open-sans-14-regular-font-weight);
    letter-spacing: var(--body-open-sans-14-regular-letter-spacing);
    line-height: var(--body-open-sans-14-regular-line-height);
    position: relative;
    text-align: center;
    white-space: nowrap;
    width: fit-content;
  }
  
  .frame-8 {
    align-items: flex-start;
    display: flex;
    gap: 39px;
    position: relative;
    width: fit-content;
  }
  
  .social {
    height: 40px;
    min-width: 146px;
    position: relative;
    @media only screen and (max-width: 280px) {
      max-width: 250px;
      font-size: 12px;
      line-height: 16px;
      text-align: center;
  }
  }
  
  .twitter-icon {
    border: 1px solid;
    border-color: #0151fd40;
    border-radius: 12px;
    height: 40px;
    left: 106px;
    position: absolute;
    top: 0;
    width: 40px;
  }
  
  .twitter {
    height: 11px;
    left: 12px;
    position: absolute;
    top: 13px;
    width: 13px;
  }
  
  .linkedin-icon {
    border: 1px solid;
    border-color: #0151fd40;
    border-radius: 12px;
    height: 40px;
    left: 53px;
    position: absolute;
    top: 0;
    width: 40px;
  }
  
  .group {
    height: 14px;
    left: 12px;
    position: absolute;
    top: 11px;
    width: 14px;
  }
  
  .facebook-icon {
    border: 1px solid;
    border-color: #0151fd40;
    border-radius: 12px;
    height: 40px;
    left: 0;
    position: absolute;
    top: 0;
    width: 40px;
  }
  
  .landing-page .shape {
    height: 15px;
    left: 15px;
    position: absolute;
    top: 11px;
    width: 7px;
  }
  
  .landing-page .bg {
    // height: 900px;
    // left: 0;
    position: absolute;
    bottom: 161px;
    left: 0;
    /* max-width: 1440px; */
    width: 100%;
  }
}
