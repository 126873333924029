.marchant-slider {
  display: flex;
  align-items: center;
  text-align: center;
  justify-content: space-between;

  @media only screen and (max-width: 600px) {
    // // padding: 5px;
    // margin-top: 5px;
    flex-direction: column;
    // margin-left: 7px;
  }

  .frame-14 {
    display: flex;
    gap: 50px;

    //MOBILE PHONES 
    @media only screen and (max-width: 320px) {
      width: 100%;
    }

    @media only screen and (max-width: 600px) {
      flex-direction: column;
      gap: 50px;
    }

    @media only screen and (max-width: 1024px) {
      width: 80%;
      display: inherit;
      gap: 50px;
    }
    @media only screen and (max-width: 1280px) {
      width: 80%;
      display: inherit;
      gap: 50px;
    }

    .image-2 {
    //   height: 509px;
    
      //MOBILE PHONES 
      @media only screen and (max-width: 320px) {
        // width: 200px;
        // height: 250px;
        max-width: 100%;
        margin: auto
      }
      //TABLETS 
      @media only screen and (max-width: 600px) {
        // width: 200px;
        // height: 250px;
        max-width: 100%;
        margin: auto
      }

      //LAPTOPS
      @media only screen and (max-width: 1020px) {
        // width: 200px;
        // height: 250px;
        max-width: 80%;
        margin: auto
      }
      @media only screen and (max-width: 1200px) {
        // width: 200px;
        // height: 250px;
        max-width: 100%;
        margin: auto
      }

      // object-fit: cover;
    }

    .frame-15 {
      margin-top: 7em;
      width: 100%;
      align-items: center;
      // gap: 16px;
      //   margin-top: 150px;
      //   width: 552px;
      justify-content: center;

      @media only screen and (max-width: 600px) {
        margin-top: 1em;
        // margin-top: 10px;
        // width: 150px;
        text-align: center;
      }
      @media only screen and (max-width: 1120px) {
        //margin-top: 1em;
        // margin-top: 10px;
        // width: 150px;
        //text-align: center;
      }

      .title-6 {
        color: var(--texticonprimary-black);
        font-family: "Open Sans", Helvetica;
        font-size: 32px;
        font-style: normal;
        font-weight: 600;
        line-height: 48px;
        line-height: 48px;
        margin-top: 15px;
        text-align: left;

        @media only screen and (max-width: 600px) {
          font-size: 24px;
          line-height: 24px;
          text-align: center;
          width: auto;
          margin-left: 25px;
        }
      }

      .title-7 {
        color: var(--texticonprimary-black);
        font-family: "Open Sans", Helvetica;
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
        line-height: 24px;
        text-align: justify;
        @media only screen and (max-width: 600px) {
          display: flex;
          justify-content: center;
          font-size: 16px;
          line-height: 24px;
          text-align: justify;
          width: auto;
        }
      }
    }
  }

  li::marker {
    content: "";
  }

  ul {
    display: flex !important;
    align-items: center;
    justify-content: center;
  }

  .slick-dots {
    margin: 0 auto;
    @media only screen and (max-width: 600px) {
      width: 100%;
    }
  }

  .slick-dots li button:before {
    height: 18px;
    width: 18px;

    color: #0151fd4c;
    font-size: 10px;
    opacity: 1;
  }

  .slick-dots li.slick-active button:before {
    display: none;
  }

  .slick-dots li.slick-active {
    background-color: #0151fd;
    border-radius: 100px;
    height: 4px;
    width: 40px;
    padding-bottom: 12px;
  }

  .iconly-sharp-bold-wrapper {
    align-items: center;
    border: 1px solid;
    border-color: #0151fd;
    border-radius: 8px;
    display: flex;
    gap: 8px;
    justify-content: center;
    padding: 16px;
    position: relative;
    width: fit-content;
    cursor: pointer;
  }

  .sm-hide {
    display: block;

    @media only screen and (max-width: 600px) {
      display: none;
    }
  }

  .moble-button {
    display: none;

    @media only screen and (max-width: 600px) {
      display: flex;
      gap: 50px;
      margin-top: 50px;
    }
  }

  .slider-carousel {
    max-width: 80%;
    margin: 0 auto;

    @media only screen and (max-width: 600px) {
      width: 100%;
      max-width: 100%;
    }

    img {
      margin: auto;
    }
  }

  .slider-center {
    margin: 0 auto;
  }

  .dot {
    display: flex !important;
    gap: 15px;
    width: 200px;
    margin: 0 auto;

    button {
      display: none;
    }
    @media only screen and (max-width: 600px) {
      margin-right: 50px;
    }
  }
}

.landing-page .title-7 {
  align-self: stretch;
  color: var(--texticonsecondary-black);
  font-family: var(--body-open-sans-16-regular-font-family);
  font-size: var(--body-open-sans-16-regular-font-size);
  font-style: var(--body-open-sans-16-regular-font-style);
  font-weight: var(--body-open-sans-16-regular-font-weight);
  letter-spacing: var(--body-open-sans-16-regular-letter-spacing);
  line-height: var(--body-open-sans-16-regular-line-height);
  position: relative;
}
