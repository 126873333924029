.navbar {
  align-items: center;
  background-color: #fff;
  display: flex;
  justify-content: space-between;
  position: relative;
  width: 100%;
  //margin-bottom: 5rem;
  @media only screen and (max-width: 280px) {
    max-width: 250px;
    padding: 0 5px;
   
}
  @media only screen and (max-width: 600px) {
    padding: 24px 15px;
    gap: 50px
  }
   @media only screen and (max-width: 1120px) {
            padding: 0 20px;
        }
}

.navbar .title {
  display: block;
  height: 24px;
  min-width: 110.32px;
  position: relative;
}

.getInTouch-btn{
  background-color: #0151FD;
  color: #ffffff;
  font-size: 16px;
  font-weight: 700;
  font-family: "Open Sans", Helvetica;
  padding: 16px 20px;
  border-radius: 8px;
  width: 150px;
  border: none;
  cursor: pointer;
  margin-top: 10px;
  border: 1px solid #0151FD;
  @media only screen and (max-width: 280px) {
   width: 80px;
   font-size: 12px;
   padding: 8px 12px;
}
  // :hover{
  //     opacity: 0.5;
  // }
}
.getInTouch-btn:hover{
  opacity: 0.9;
  background-color: #ffffff;
  color: #0151FD;
  border: 1px solid #0151FD;
}

.navbar .frame-2 {
  align-items: center;
  display: flex;
  gap: 32px;
  position: relative;
  width: fit-content;
}

.navbar .title-2 {
  color: var(--texticonprimary-black);
  font-family: var(--heading-open-sans-16-regular-font-family);
  font-size: var(--heading-open-sans-16-regular-font-size);
  font-style: var(--heading-open-sans-16-regular-font-style);
  font-weight: var(--heading-open-sans-16-regular-font-weight);
  letter-spacing: var(--heading-open-sans-16-regular-letter-spacing);
  line-height: var(--heading-open-sans-16-regular-line-height);
  margin-top: -1px;
  position: relative;
  white-space: nowrap;
  width: fit-content;
}

.navbar .frame-3 {
  align-items: center;
  display: flex;
  gap: 16px;
  position: relative;
  width: fit-content;
}

.navbar .remix-icons-line {
  height: 24px !important;
  min-width: 24px !important;
  position: relative !important;
}

.navbar .frame-4 {
  align-items: flex-start;
  display: flex;
  gap: 32px;
  position: relative;
  width: fit-content;
}

.navbar .regular-buttons-instance {
  border-color: #0151fd !important;
  padding: 16px 20px !important;
  width: 150px !important;
}

.navbar .instance-node {
  color: #0151fd !important;
  font-family: var(--heading-open-sans-16-bold-font-family) !important;
  font-size: var(--heading-open-sans-16-bold-font-size) !important;
  font-style: var(--heading-open-sans-16-bold-font-style) !important;
  font-weight: var(--heading-open-sans-16-bold-font-weight) !important;
  letter-spacing: var(--heading-open-sans-16-bold-letter-spacing) !important;
  line-height: var(--heading-open-sans-16-bold-line-height) !important;
  white-space: nowrap !important;
}

.navbar .regular-buttons-2 {
  background-color: #0151fd !important;
  border: 1px solid !important;
  border-color: #0151fd !important;
  padding: 16px 20px !important;
  width: 150px !important;
}

.navbar .regular-buttons-3 {
  font-family: var(--heading-open-sans-16-bold-font-family) !important;
  font-size: var(--heading-open-sans-16-bold-font-size) !important;
  font-style: var(--heading-open-sans-16-bold-font-style) !important;
  font-weight: var(--heading-open-sans-16-bold-font-weight) !important;
  letter-spacing: var(--heading-open-sans-16-bold-letter-spacing) !important;
  line-height: var(--heading-open-sans-16-bold-line-height) !important;
  white-space: nowrap !important;
}

.navbar .design-component-instance-node-2 {
  background-color: #0151fd !important;
  border: 1px solid !important;
  padding: 16px 20px !important;
  width: 150px !important;
  color: #ffffff;
}