.regular-buttons {
  align-items: center;
  display: flex;
  justify-content: center;
  position: relative;
  width: fit-content;
  border:  none;
}

.regular-buttons .iconly-regular-outline-home {
  height: 18px !important;
  width: 17px !important;
}

.regular-buttons .iconly-regular-instance {
  height: 20px !important;
  min-width: 20px !important;
  width: unset !important;
}

.regular-buttons .button-label {
  margin-top: -1px;
  position: relative;
  width: fit-content;
}

.regular-buttons .div {
  align-items: center;
  display: flex;
  gap: 8px;
  justify-content: center;
  position: relative;
  width: fit-content;
}

.regular-buttons .text-wrapper {
  position: relative;
  width: fit-content;
}

.regular-buttons.elevated {
  box-shadow: var(--shadow-8);
}

.regular-buttons.pressed {
  gap: 8px;
}

.regular-buttons.hover {
  gap: 8px;
}

.regular-buttons.disabled {
  gap: 8px;
}

.regular-buttons.default {
  gap: 8px;
}

.regular-buttons.focused {
  border: 2px solid;
  flex-direction: column;
  gap: 10px;
}

.regular-buttons.default.small {
  border-radius: 12px;
  padding: 12px 20px;
}

.regular-buttons.focused.normal {
  border-radius: 12px;
  padding: 6px;
}

.regular-buttons.secondary.hover {
  border: 1px solid;
  border-color: var(--primary-800);
  box-shadow: var(--shadow-8);
}

.regular-buttons.hover.small {
  border-radius: 12px;
  padding: 12px 20px;
}

.regular-buttons.focused.elevated {
  border-color: var(--primary-800);
}

.regular-buttons.focused.primary {
  border-color: var(--primary-800);
}

.regular-buttons.hover.elevated {
  background-color: var(--primary-800);
}

.regular-buttons.primary.default {
  background-color: var(--primary-700);
}

.regular-buttons.default.elevated {
  background-color: var(--primary-700);
}

.regular-buttons.hover.tertiary {
  background-color: var(--neutral-100);
}

.regular-buttons.default.error {
  background-color: var(--error-700);
}

.regular-buttons.primary.hover {
  background-color: var(--primary-800);
  box-shadow: var(--shadow-8);
}

.regular-buttons.elevated.disabled {
  background-color: var(--neutral-200);
}

.regular-buttons.pressed.error {
  background-color: var(--error-900);
}

.regular-buttons.focused.tertiary {
  border-color: var(--primary-100);
}

.regular-buttons.focused.small {
  border-radius: 16px;
  padding: 4px;
}

.regular-buttons.pressed.normal {
  border-radius: 8px;
  padding: 18px 20px;
}

.regular-buttons.hover.normal {
  border-radius: 8px;
  padding: 18px 20px;
}

.regular-buttons.pressed.elevated {
  background-color: var(--primary-900);
}

.regular-buttons.hover.error {
  background-color: var(--error-800);
  box-shadow: var(--shadow-8);
}

.regular-buttons.disabled.error {
  background-color: var(--neutral-200);
}

.regular-buttons.focused.secondary {
  border-color: var(--primary-800);
}

.regular-buttons.pressed.small {
  border-radius: 12px;
  padding: 12px 20px;
}

.regular-buttons.primary.pressed {
  background-color: var(--primary-900);
}

.regular-buttons.small.disabled {
  border-radius: 12px;
  padding: 12px 20px;
}

.regular-buttons.secondary.disabled {
  border: 1px solid;
  border-color: var(--neutral-700);
}

.regular-buttons.pressed.tertiary {
  background-color: var(--neutral-200);
}

.regular-buttons.focused.error {
  border-color: var(--error-800);
}

.regular-buttons.disabled.normal {
  border-radius: 8px;
  padding: 18px 20px;
}

.regular-buttons.secondary.default {
  border: 1px solid;
  border-color: var(--primary-700);
}

.regular-buttons.default.normal {
  border-radius: 8px;
  padding: 18px 20px;
}

.regular-buttons.primary.disabled {
  background-color: var(--neutral-200);
}

.regular-buttons.secondary.pressed {
  border: 1px solid;
  border-color: var(--primary-900);
}

.regular-buttons.disabled .button-label {
  color: var(--texticondisabled-black);
}

.regular-buttons.small .button-label {
  font-family: var(--button-links-medium-font-family);
  font-size: var(--button-links-medium-font-size);
  font-style: var(--button-links-medium-font-style);
  font-weight: var(--button-links-medium-font-weight);
  letter-spacing: var(--button-links-medium-letter-spacing);
  line-height: var(--button-links-medium-line-height);
  white-space: nowrap;
}

.regular-buttons.normal .button-label {
  font-family: var(--button-links-large-font-family);
  font-size: var(--button-links-large-font-size);
  font-style: var(--button-links-large-font-style);
  font-weight: var(--button-links-large-font-weight);
  letter-spacing: var(--button-links-large-letter-spacing);
  line-height: var(--button-links-large-line-height);
}

.regular-buttons.focused .button-label {
  color: var(--texticonprimary-white);
}

.regular-buttons.default.tertiary .button-label {
  color: var(--primary-700);
}

.regular-buttons.secondary.hover .button-label {
  color: var(--primary-800);
}

.regular-buttons.hover.elevated .button-label {
  color: var(--texticonprimary-white);
}

.regular-buttons.primary.default .button-label {
  color: var(--texticonprimary-white);
}

.regular-buttons.default.elevated .button-label {
  color: var(--texticonprimary-white);
}

.regular-buttons.hover.tertiary .button-label {
  color: var(--primary-800);
}

.regular-buttons.default.error .button-label {
  color: var(--texticonprimary-white);
}

.regular-buttons.primary.hover .button-label {
  color: var(--texticonprimary-white);
}

.regular-buttons.pressed.error .button-label {
  color: var(--texticonprimary-white);
}

.regular-buttons.pressed.elevated .button-label {
  color: var(--texticonprimary-white);
}

.regular-buttons.hover.error .button-label {
  color: var(--texticonprimary-white);
}

.regular-buttons.primary.pressed .button-label {
  color: var(--texticonprimary-white);
}

.regular-buttons.pressed.tertiary .button-label {
  color: var(--primary-900);
}

.regular-buttons.secondary.default .button-label {
  color: var(--primary-700);
}

.regular-buttons.secondary.pressed .button-label {
  color: var(--primary-900);
}

.regular-buttons.elevated .div {
  background-color: var(--primary-800);
}

.regular-buttons.tertiary .div {
  background-color: var(--neutral-100);
}

.regular-buttons.secondary .div {
  background-color: var(--neutral-200);
  border: 1px solid;
  border-color: var(--primary-800);
}

.regular-buttons.primary .div {
  background-color: var(--primary-800);
}

.regular-buttons.small .div {
  border-radius: 12px;
  padding: 12px 20px;
}

.regular-buttons.error .div {
  background-color: var(--error-700);
}

.regular-buttons.normal .div {
  border-radius: 8px;
  padding: 18px 20px;
}

.regular-buttons.elevated .text-wrapper {
  color: var(--texticonprimary-white);
}

.regular-buttons.tertiary .text-wrapper {
  color: var(--primary-800);
  margin-top: -1px;
}

.regular-buttons.secondary .text-wrapper {
  color: var(--primary-800);
  margin-top: -1px;
}

.regular-buttons.primary .text-wrapper {
  color: var(--texticonprimary-white);
}

.regular-buttons.small .text-wrapper {
  font-family: var(--button-links-medium-font-family);
  font-size: var(--button-links-medium-font-size);
  font-style: var(--button-links-medium-font-style);
  font-weight: var(--button-links-medium-font-weight);
  letter-spacing: var(--button-links-medium-letter-spacing);
  line-height: var(--button-links-medium-line-height);
  white-space: nowrap;
}

.regular-buttons.error .text-wrapper {
  color: var(--texticonprimary-white);
}

.regular-buttons.normal .text-wrapper {
  font-family: var(--button-links-large-font-family);
  font-size: var(--button-links-large-font-size);
  font-style: var(--button-links-large-font-style);
  font-weight: var(--button-links-large-font-weight);
  letter-spacing: var(--button-links-large-letter-spacing);
  line-height: var(--button-links-large-line-height);
}
